div:focus {
  outline-style: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Eina01', sans-serif;
  color: #343434;
}

#root {
  height: 100%;
}

kbd {
  background-color: #fff;
  padding: 0.25rem 0.5rem;
  border: thin solid #dadada;
  border-radius: 0.25rem;
}

dl {
  margin: 0;
}

dt,
dd {
  display: inline-block;
  color: #616161;
}

dd {
  margin-inline-start: 0;
  margin-inline-end: 1rem;
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}
